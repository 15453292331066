import { PinkCircles0, PinkCircles1 } from "@assets/Background";
import { PageLayout, TrustedBy } from "@components";
import BackgroundFadeContainer from "@components/BackgroundFadeContainer/BackgroundFadeContainer";
import { BackgroundShapes } from "@components/BackgroundShapes/BackgroundShapes";
import { GetStartedButton } from "@components/Button_new/Button";
import CentralizedPlatform from "@components/CentralizedPlatform/CentralizedPlatform";
import ImageAndBulletPoints from "@components/ImageAndBulletPoints/ImageAndBulletPoints";
import { SectionTitle } from "@components/SectionTitle/SectionTitle";
import TryGenemodForFree from "@components/TryGenemodForFree/Index";
import { pinkText } from "@components/Typography/Typography";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export default function Protocols() {
	return (
		<PageLayout
			seoOptions={{ type: "PREDEFINED", pageName: "lab-protocol" }}
		>
			<Hero />
			<DigitizeResearch />
			<AllInOneNoteBook />
			<TryGenemodForFree type="blueFade" />
			<TrustedBy backgroundType="solidLightPink" noBottomSkew />
		</PageLayout>
	);
}

function Hero() {
	return (
		<BackgroundFadeContainer type="purpleBlueFade" skewBottom>
			<div
				style={{
					marginBottom: 40,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<SectionTitle
					title="Build, version control, and collaborate within a shared protocol template library"
					subtitle="Genemod allows your team to create, version control, and execute protocol templates so you don’t need to reinvent the wheel."
					textColor="ui-01"
					marginTop={163}
					marginTopTablet={186}
					marginTopMobile={146}
					titleWidth={968}
					subtitleWidth={722}
				/>
				<GetStartedButton />
				<CentralizedPlatform style={{ marginTop: 24 }} />
			</div>
		</BackgroundFadeContainer>
	);
}

function DigitizeResearch() {
	return (
		<BackgroundFadeContainer type="solidLightPink" skewTop skewBottom>
			<BackgroundShapes
				leftShape={{
					shape: <PinkCircles0 />,
					xOffset: -70,
					yOffset: 20,
				}}
			>
				<SectionTitle
					title={pinkText`Digitize your research protocols to ${"improve reproducibility"}`}
					textColor="text-dark"
					titleWidth={804}
					marginBottom={60}
					marginBottomTablet={12}
					marginBottomMobile={12}
				/>
				<ImageAndBulletPoints
					imagePosition="left"
					image={
						<StaticImage
							loading="eager"
							src="../../../assets/products/protocol-library.png"
							alt="protocol library"
							width={786}
							height={586}
						/>
					}
					withImageShadowMargins
					bullets={[
						{
							title: "Bring structure to your research",
							text: "Create flexible and editable protocol templates that scales and streamline testing procedures.",
						},
						{
							title: "Built for 21 CFR Part 11 compliance",
							text: "Easily manage version history without the need to save multiple protocol copies.",
						},
						{
							title: "Import and export your data",
							text: "Genemod makes it easy for you to move content to & from other programs.",
						},
					]}
					bulletStyles={{
						titleVariant: "HEADER4",
						titleColor: "marine",
						textVariant: "SUBHEADERDESC",
						textColor: "text-dark",
						containerWidth: 476,
					}}
				/>
			</BackgroundShapes>
		</BackgroundFadeContainer>
	);
}

function AllInOneNoteBook() {
	return (
		<BackgroundFadeContainer type="pinkFade" skewTop skewBottom>
			<BackgroundShapes
				leftShape={{ shape: <></> }}
				rightShape={{
					shape: <PinkCircles1 />,
					xOffset: 100,
					yOffset: 300,
				}}
			>
				<SectionTitle
					title={pinkText`The ${"all-in-one"} notebook for research anytime, anywhere.`}
					textColor="text-dark"
					titleWidth={800}
					marginBottom={60}
					marginBottomTablet={60}
				/>
				<ImageAndBulletPoints
					image={
						<StaticImage
							loading="eager"
							src="../../../assets/products/exp_editor.png"
							alt="experiment editor"
							width={786}
							height={586}
						/>
					}
					withImageShadowMargins
					bullets={[
						{
							title: "Collaborative real-time editing",
							text: "We take teamwork to the next level by letting you and your team work together in real time on experiments.",
						},
						{
							title: "Create, comment, and reply to protocols",
							text: "Make it a team effort with in-line and page comments and replies.",
						},
						{
							title: "Centralize attachments and metadata",
							text: "Easily upload and manage relevant attachments within experiments, as well as create new sections.",
						},
					]}
					bulletStyles={{
						titleVariant: "HEADER4",
						titleColor: "marine",
						textVariant: "SUBHEADERDESC",
						textColor: "text-dark",
						containerWidth: 476,
					}}
				/>
			</BackgroundShapes>
		</BackgroundFadeContainer>
	);
}
